import { PayloadModel } from "../types/PayloadModel";
import { TranslationModel } from "../types/TranslationModel";
import { UnemploymentInsuranceOptionModel } from "../types/UnemploymentInsuranceOptionModel";
import { UnionOptionModel } from "../types/UnionOptionModel";
import axios from "axios";
import { ContactLeadModel } from "../types/ContactLeadModel";
import { EmailValidationResponse } from "../types/EmailValidationResponse";
import { AddressLookupResponse } from "../types/AddressLookupResponse";
import { ChildrenEnrollmentPayload } from "../types/ChildrenEnrollmentPayload";
import { PartnerInviteRequest } from "../types/PartnerInviteRequest";
import { RatingRequest } from "../types/RatingRequest";
import { TrackTraceResponseModel } from "../types/TrackTraceResponseModel";
import { TrackTraceUnionRequestModel } from "../types/TrackTraceUnionRequestModel";
import { TrackTraceSalaryInsuranceRequestModel } from "../types/TrackTraceSalaryInsuranceRequestModel";
import { GiftCardResponse } from "../types/GiftCardResponse";
import { GiftCardStatusResponse } from "../types/GiftCardStatusResponse";
import { SalaryInsuranceRequestModel } from "../types/SalaryInsuranceRequestModel";

export const fetchSignupDate = async (payload: PayloadModel) => {
  return axios.post("/api/oi/getsignupdate", {
    ...payload,
  });
};

export const postAllowContact = async (payload: ContactLeadModel) => {
  return axios.post("/api/oi/submit", {
    ...payload,
  });
};

export const postEnrollment = async (payload: PayloadModel) => {
  return axios.post<{ url: string }>("/api/oi/submit", {
    ...payload,
  });
};

export const postChildrenEnrollment = async (payload: ChildrenEnrollmentPayload) => {
  return axios.post("/api/oi/submitchildren", {
    ...payload,
  });
};

export const fetchTranslations = async (url?: string) => {
  return axios.get<TranslationModel>(url || "/assets/data/translations.json");
};

export const fetchUnionOptions = async () => {
  return axios.get<UnionOptionModel[]>("/assets/data/unions.json");
};

export const fetchUnemploymentInsuranceOptions = async () => {
  return axios.get<UnemploymentInsuranceOptionModel[]>("/assets/data/unemploymentinsurancefunds.json");
};

export const postEmailValidation = async (email: string) => {
  return axios.post<EmailValidationResponse>("/api/oi/validateemail", {
    email,
    source: "enroll member",
  });
};

export const lookupAddress = async (address: string, caretpos: string, startFrom?: string, addressId?: string) => {
  let query = `?q=${address}&type=adresse&caretpos=${caretpos}&supplerendebynavn=true&stormodtagerpostnumre=true&multilinje=true&fuzzy=`;

  if (startFrom) {
    query += `&startfra=${startFrom}`;
  }

  if (addressId) {
    query += `&adgangsadresseid=${addressId}`;
  }

  return axios.get<AddressLookupResponse[]>(`https://api.dataforsyningen.dk/autocomplete${query}`);
};

export const submitPartnerInvite = async (payload: PartnerInviteRequest) => {
  return axios.post("/api/oi/submitpartnerinvite", {
    ...payload,
  });
};

export const postRating = async (payload: RatingRequest) => {
  return axios.post("/api/oi/rating", {
    ...payload,
  });
};

export const getTrackAndTraceEvents = async (id: string) => {
  return axios.get<TrackTraceResponseModel>(`/api/oi/gettrackandtrace?id=${id}`);
};

export const postTrackAndTraceBuyUnion = async (payload: TrackTraceUnionRequestModel) => {
  return axios.post("/api/oi/trackandtracebuyunion", {
    ...payload,
  });
};

export const postTrackAndTraceBuySalaryInsurance = async (payload: TrackTraceSalaryInsuranceRequestModel) => {
  return axios.post("/api/oi/addsaleryinsurance", {
    ...payload,
  });
};

export const fetchGiftCard = async (occupation: "Employee" | "Unemployed" | "SelfEmployed" | "Student" | undefined, front: boolean) => {
  return axios.get<GiftCardResponse>(`/api/giftcard/checkforgiftcard?target=${occupation?.toLocaleLowerCase()}&front=${front}`);
};

export const fetchGiftCardProducts = async (occupation: "Employee" | "Unemployed" | "SelfEmployed" | "Student" | undefined, products: string) => {
  return axios.get<GiftCardResponse>(`/api/giftcard/checkforgiftcard?target=${occupation?.toLocaleLowerCase()}&products=${products}`);
};

export const fetchGiftCardStatus = async (guid: string) => {
  return axios.get<GiftCardStatusResponse>(`/api/giftcard/getgiftcardsleft?giftcardid=${guid}`);
};

export const postSalaryInsuranceData = async (payload: SalaryInsuranceRequestModel) => {
  return axios.post(`/umbraco/api/SalaryCalculatorApi/PostSalCalCampaign2021`, payload);
};
