import Button from "../Button/Button";
import { Container, Footer, Header, Body, Title, IconWrapper, Name, NumberOfPeople, Description, Inner, Price } from "./ProductCard.styles";
import { MdOutlineFamilyRestroom, MdOutlineBoy, MdInfoOutline, MdCheck } from "react-icons/md";
import { ProductTypesEnum } from "../../types/ProductTypesEnum";
import { useMemo, useState } from "react";
import Modal from "../Modal";

interface ProductCardProps {
  type: ProductTypesEnum;
  lead: string;
  name: string;
  description: string;
  numberOfPeople: string;
  active: boolean;
  modalDescriptionHtml?: string;
  price?: string;
  onActive: () => void;
}

export default function ProductCard({ type, name, lead, description, numberOfPeople, active, modalDescriptionHtml, price, onActive }: ProductCardProps): JSX.Element {
  const [showModal, setShowModal] = useState(false);

  const icon = useMemo(() => {
    switch (type) {
      case ProductTypesEnum.FamilyUnion:
        return <MdOutlineFamilyRestroom size={64} color="#008E9F" />;
      case ProductTypesEnum.Union:
        return <MdOutlineBoy size={74} color="#008E9F" />;
      default:
        return <span>Not implemented</span>;
    }
  }, [type]);

  return (
    <>
      <Container active={active} onClick={onActive}>
        <Inner active={active}>
          <Header>
            <Title active={active}>{lead}</Title>
          </Header>
          <Body>
            <IconWrapper>{icon}</IconWrapper>

            <Name>
              <span>{name}</span>
              <span
                style={{ height: 20, marginLeft: 5 }}
                onClick={(e) => {
                  setShowModal(true);
                  e.stopPropagation();
                }}
              >
                <MdInfoOutline size={24} />
              </span>
            </Name>
            <NumberOfPeople>{numberOfPeople}</NumberOfPeople>
            {price && <Price>{price} kr./md.</Price>}
            <Description>{description}</Description>
          </Body>
          <Footer>
            <Button style={{ height: 34, width: "100%", background: active ? "var(--color-green-2)" : "" }}>
              <span style={{ paddingLeft: active ? 30 : 0, paddingRight: active ? 5 : 0 }}>{active ? "Valgt" : "Vælg"}</span>
              {active && <MdCheck size={24} />}
            </Button>
          </Footer>
        </Inner>
      </Container>
      {modalDescriptionHtml && <Modal onClose={() => setShowModal(false)} open={showModal} modalDescriptionHtml={modalDescriptionHtml} />}
    </>
  );
}
