import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "../../store/store";
import StepRoutes from "../../constants/Routes";
import OccupationStep from "./Steps/OccupationStep";
import ProductsStep from "./Steps/ProductsStep";
import WorkingDenmarkStep from "./Steps/WorkingDenmarkStep";
import SelfEmployedStep from "./Steps/SelfEmployedStep";
import ReceivingUnemploymentBenefitsStep from "./Steps/ReceivingUnemploymentBenefitsStep";
import FitWithYouStep from "./Steps/FitWithYouStep";
import NeedToContactYouStep from "./Steps/NeedToContactYouStep";
import InformationAboutYouStep from "./Steps/InformationAboutYouStep";
import SummaryStep from "./Steps/SummaryStep";
import CompletedStep from "./Steps/CompletedStep";
import CompletedContactYouStep from "./Steps/CompletedContactYouStep";
import PaymentStep from "./Steps/PaymentStep";
import { ConfigModel } from "../../types/ConfigModels";
import { fetchTranslations, fetchUnemploymentInsuranceOptions, fetchUnionOptions } from "../../utils/api";
import MembershipStep from "./Steps/MembershipStep";
import ConsentStep from "./Steps/ConsentStep";
import { WrapperInner } from "./FormEnrollment.styles";
import GiftCardPopup from "../../components/GiftCardPopup";
import axios from "axios";

export default function FormEnrollment() {
  const routeStack = useStore.useRouteStack();
  const setPayload = useStore.useSetPayload();
  const setOfferEnabled = useStore.useSetOfferEnabled();
  const setTranslations = useStore.useSetTranslation();
  const setUnionOptions = useStore.useSetUnionOptions();
  const setUnemploymentInsuranceOptions = useStore.useSetUnimploymentInsuranceOptions();

  const getIpAddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    //console.log(res.data);
    setPayload({ ipAddress : res.data.ip });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let translations: any;
        const settingsUrl = form?.dataset.jsonSettingsUrl;

        

        translations = await fetchTranslations(settingsUrl);
        setTranslations(translations.data);

        const unemploymentInsuranceOptions = await fetchUnemploymentInsuranceOptions();
        const unionOptions = await fetchUnionOptions();

        setUnionOptions(unionOptions.data);
        setUnemploymentInsuranceOptions(unemploymentInsuranceOptions.data);

        // If an occupation is passed in the query string, set it as the default value
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const occupation = urlParams.get("type");

        if (occupation === "employee") {
          setPayload({ occupation: "Employee" });
        }
        if (occupation === "selfemployed") {
          setPayload({ occupation: "SelfEmployed" });
        }
        if (occupation === "unemployed") {
          setPayload({ occupation: "Unemployed" });
        }
        if (occupation === "student") {
          setPayload({ occupation: "Student" });
        }
        
        getIpAddress();
        
      } catch (err) {}
    };

    const form = document.querySelector(`[data-component='form-enrollment']`) as HTMLElement;

    fetchData();
    if (form?.dataset.config && form?.dataset.config !== "null") {
      const config = JSON.parse(form?.dataset.config) as ConfigModel;

      setOfferEnabled(config.EnableDiscounts);
      setPayload({ settingsNodeKey: config.Key });
    }
  }, []);

  const currentStep = useMemo(() => {
    return routeStack[routeStack.length - 1];
  }, [routeStack]);

  const step = useMemo(() => {
    switch (currentStep) {
      case StepRoutes.OccupationStep:
        return <OccupationStep />;
      case StepRoutes.ProductStep:
        return <ProductsStep />;
      case StepRoutes.MembershipStep:
        return <MembershipStep />;
      case StepRoutes.WorkingDenmarkStep:
        return <WorkingDenmarkStep />;
      case StepRoutes.FitWithYouStep:
        return <FitWithYouStep />;
      case StepRoutes.NeedToContactYouStep:
        return <NeedToContactYouStep />;
      case StepRoutes.ReceivingUnemploymentBenefitsStep:
        return <ReceivingUnemploymentBenefitsStep />;
      case StepRoutes.SelfEmployedStep:
        return <SelfEmployedStep />;
      case StepRoutes.InformationAboutYouStep:
        return <InformationAboutYouStep />;
      case StepRoutes.SummaryStep:
        return <SummaryStep />;
      case StepRoutes.PaymentStep:
        return <PaymentStep />;
      case StepRoutes.CompletedStep:
        return <CompletedStep />;
      case StepRoutes.CompletedContactYouStep:
        return <CompletedContactYouStep />;
      case StepRoutes.ConsentStep:
        return <ConsentStep />;
    }
  }, [currentStep]);

  const showGiftCardPopup = useMemo(() => {
   /*  if (currentStep === StepRoutes.InformationAboutYouStep) {
      setIsfrontCheck(false);
    } */
    return currentStep !== StepRoutes.CompletedStep && currentStep !== StepRoutes.CompletedContactYouStep && currentStep !== StepRoutes.NeedToContactYouStep;
  }, [currentStep]);

  return (
    <div
      className="ASEContainer"
      id="ASEEnrollmentForm"
      style={{
        backgroundColor: currentStep === StepRoutes.CompletedStep || currentStep === StepRoutes.CompletedContactYouStep ? "var(--color-petrol)" : "transparent", 
      }}
    >
      {showGiftCardPopup === true && (
        <GiftCardPopup isFront={true} />
      )}
      <WrapperInner>
        {step}
      </WrapperInner>
    </div>
  );
}
