import { useStore } from "../../../../store/store";
import StepRoutes from "../../../../constants/Routes";
import { InnerRow, ProductCheckbox, ProductNameCheckbox, ProductSelectionWrapper, ProductSelectionWrapperRow, Row, Spacer, StepQuestion } from "./CurrentStatusStep.styles";
import Checkbox from "../../../../components/Checkbox";
import { useMemo } from "react";
import StepContainer from "../../../../components/StepContainer";
import { formatNumber } from "../../../../utils/formatNumber";
import TotalAmountBox from "../../../../components/TotalAmountBox";
import { PaymentTypeEnum } from "../../../../types/PaymentTypesEnum";

export default function CurrentStatusStep() {
  const t = useStore.useTranslations();
  const payload = useStore.usePayload();
  const setHasUnemploymentbenifit = useStore.useSetHasUnemploymentbenifit();
  const hasUnemploymentbenifit = useStore.useHasUnemploymentbenifit();
  const setCurrentlyAseMember = useStore.useSetCurrentlyAseMember();
  const currentlyAseMember = useStore.useCurrentlyAseMember();
  const setPayload = useStore.useSetPayload();
  const setRouteStack = useStore.useSetRouteStack();

  const rows = useMemo(() => {
    const r = [];

    let totalAmount = 0;
    const UnemploymentInsurancePrice = Number(t?.productsStep.unemploymentInsurance.price);

    if ((currentlyAseMember && !hasUnemploymentbenifit) || !currentlyAseMember) {
      totalAmount = totalAmount + UnemploymentInsurancePrice;
      r.push({
        label: t?.productsStep.unemploymentInsurance.title,
        value: `${formatNumber(UnemploymentInsurancePrice)} ${t?.general.krPerMonth}`,
      });
    }

    r.push({
      label: `${t?.productsStep.familyUnion.title} (tilføjes som partner)`,
      value: `0 ${t?.general.krPerMonth}`,
    });

    r.push({
      label: t?.general.totalAmount,
      value: `${formatNumber(totalAmount)} ${t?.general.krPerMonth}`,
      bold: true,
    });

    return r as { label: string; value: string; bold?: boolean }[];
  }, [hasUnemploymentbenifit, currentlyAseMember, t]);

  return (
    <StepContainer
      progress={0}
      buttonText={t?.occupationStep.buttonLabel}
      helpText={t?.occupationStep.helpText}
      subtitle="Velkommen til familiefagforeningen!"
      onNext={() => {
        if (currentlyAseMember && hasUnemploymentbenifit) {
          setRouteStack(StepRoutes.NeedToContactYouStep);
          setPayload({ cause: "Eksisterende a-kasse medlem forsøger at tilmelde sig som partner til familiefagforening" });
        } else {
          setRouteStack(StepRoutes.OccupationStep);
          setPayload({ wantsUnemploymentBenefits: true, wantsFamilyUnion: true, wantsSalaryInsurance: false, wantsHRJura: false, wantsUnion: false, paymentType: PaymentTypeEnum.BSPAYMENT });
        }
      }}
      hideBackButton
    >
      <Row>
        <StepQuestion>Er du allerede medlem af Ase?</StepQuestion>
        <Spacer />
        <InnerRow>
          <Checkbox
            onChange={() => {
              setCurrentlyAseMember(true);
            }}
            text={t?.unemploymentInsuranceStep.answerYes}
            checked={currentlyAseMember}
          />
          <Checkbox
            onChange={() => {
              setCurrentlyAseMember(false);
              setPayload({ currentlyMemberOfAUnion: false, currentUnion: undefined });
              setHasUnemploymentbenifit(false);
            }}
            text={t?.unemploymentInsuranceStep.answerNo}
            checked={!currentlyAseMember}
          />
        </InnerRow>
      </Row>

      {currentlyAseMember && (
        <ProductSelectionWrapper>
          <ProductSelectionWrapperRow onClick={() => setHasUnemploymentbenifit(!hasUnemploymentbenifit)}>
            <ProductCheckbox active={hasUnemploymentbenifit}></ProductCheckbox>
            <ProductNameCheckbox>A-Kasse</ProductNameCheckbox>
          </ProductSelectionWrapperRow>

          <ProductSelectionWrapperRow
            onClick={() => {
              setPayload({ currentlyMemberOfAUnion: !payload.currentlyMemberOfAUnion, currentUnion: payload.currentUnion ? undefined : "0" });
            }}
          >
            <ProductCheckbox active={Boolean(payload.currentlyMemberOfAUnion)}></ProductCheckbox>
            <ProductNameCheckbox>Fagforening</ProductNameCheckbox>
          </ProductSelectionWrapperRow>
        </ProductSelectionWrapper>
      )}

      <TotalAmountBox rows={rows} hideHeader />
    </StepContainer>
  );
}
