import { BiCollapseAlt } from "react-icons/bi";
import { Inner, PopupAvailable, PopupCollapsButton, PopupContent, PopupSubTitle, PopupTitle, PopupWrapper, PulseCircle, Wrapper } from "./GiftCardPopup.styles";
import { useEffect, useMemo, useRef, useState } from "react";
import { TbConfetti, TbConfettiOff } from "react-icons/tb";
import { useWindowResize } from "../../hooks/useWindowResize";
import { MdCelebration, MdOutlineInfo } from "react-icons/md";
import useScrollPosition from "@react-hook/window-scroll";
import { useStore } from "../../store/store";
import { fetchGiftCard, fetchGiftCardProducts, fetchGiftCardStatus } from "../../utils/api";
import { GiftCardResponse } from "../../types/GiftCardResponse";
import { GiftCardStatusResponse } from "../../types/GiftCardStatusResponse";
import Modal from "../Modal";
import StepRoutes from "../../constants/Routes";
import CurrentStatusStep from "../../features/CompanionEnrollment/Steps/CurrentStatusStep";

const POLLING_INTERVAL = 5000;

export interface Props {
  isFront: boolean;
}

export default function GiftCardPopup({isFront = true}: Props) {
  const intervalRef = useRef<NodeJS.Timer[]>([]);
  const payload = useStore.usePayload();
  const setPayload = useStore.useSetPayload();
  const [show, setShow] = useState(true);
  const scrollY = useScrollPosition(120);
  const [offset, setOffst] = useState(0);
  const [giftCardContent, setGiftCardContent] = useState<GiftCardResponse | null>(null);
  const [giftCardStatus, setGiftCardStatus] = useState<GiftCardStatusResponse | null>(null);
  const [size, setSize] = useState<{ width: string; height: string }>({ width: "auto", height: "auto" });
  const wrapperElmRef = useRef<HTMLDivElement>(null);
  const contentElmRef = useRef<HTMLDivElement>(null);
  const wrapperWidth = useRef<number>(0);
  const wrapperHeight = useRef<number>(0);
  const windowSize = useWindowResize();
  const [showModal, setShowModal] = useState(false);
  const routeStack = useStore.useRouteStack();

  const fetchGiftCardInfo = async () => {
    if (!payload.occupation) return;
    try {
      const response = await fetchGiftCard(payload.occupation, isFront);
      if(response.data.giftCardId !== null) {
        setGiftCardContent(response.data);
        setPayload({ giftcardguid: response.data.giftCardId, giftcard: true });
        startGiftCardPolling(response.data.giftCardId);
      } else {
        setGiftCardContent(null);
        setGiftCardStatus(null);
        setPayload({ giftcardguid: undefined, giftcard: false });
        stopGiftCardPolling();
      }
    } catch (error) {
      setGiftCardContent(null);
      setGiftCardStatus(null);
      setPayload({ giftcardguid: undefined, giftcard: false });
      stopGiftCardPolling();
    }
  };

  useEffect(() => {
    fetchGiftCardInfo();
  }, [payload.occupation]);


  const fetchGiftCardInfoProducts = async () => {
    if (routeStack.length < 2) return;
    try {
      const chosenProducts = [];
      if(payload.wantsUnion) {
        chosenProducts.push("union")
      }
      if(payload.wantsUnemploymentBenefits) {
        chosenProducts.push("unemploymentBenefits")
      }
      if(payload.wantsSalaryInsurance) {
        chosenProducts.push("saleryInsurance")
      }
      if(payload.wantsFamilyUnion) {
        chosenProducts.push("familyUnion")
      }
      if(payload.wantsHRJura) {
        chosenProducts.push("law") 
      }
      if(payload.wantsBasic) {
        chosenProducts.push("basis")
      }

      const response = await fetchGiftCardProducts(payload.occupation, chosenProducts.join(","));
      if(response.data.giftCardId !== null) {
        setGiftCardContent(response.data);
        setPayload({ giftcardguid: response.data.giftCardId, giftcard: true });
        startGiftCardPolling(response.data.giftCardId);

      } else {
        setGiftCardContent(null);
        setGiftCardStatus(null);
        setPayload({ giftcardguid: undefined, giftcard: false });
        stopGiftCardPolling();
      }
    } catch (error) {
      setGiftCardContent(null);
      setGiftCardStatus(null);
      setPayload({ giftcardguid: undefined, giftcard: false });
      stopGiftCardPolling();
    }
  };

  useEffect(() => {
    fetchGiftCardInfoProducts();
  }, [payload.wantsUnemploymentBenefits, payload.wantsUnion, payload.wantsFamilyUnion, payload.wantsSalaryInsurance, payload.wantsBasic, payload.wantsHRJura]);


  const setOffset = () => {
    const elm = document.querySelector(`[data-component='header']`);
    const rect = elm?.getBoundingClientRect();
    const scrollDownClass = elm?.classList.contains("scroll-down");

    if (rect) {
      setOffst(Math.round(rect.height + (scrollDownClass ? -65 : 0)));
    }
  };

  const adjustWrapperSize = () => {
    setSize({ width: "auto", height: "auto" });
    setShow(true);
    setTimeout(() => {
      const wrapperElm = wrapperElmRef.current;
      wrapperWidth.current = wrapperElm?.getBoundingClientRect().width ?? 0;
      wrapperHeight.current = wrapperElm?.getBoundingClientRect().height ?? 0;
      setSize({ width: `${wrapperWidth.current}px`, height: `${wrapperHeight.current}px` });
    }, 500);
  };

  const stopGiftCardPolling = () => {
    intervalRef.current.forEach((interval: any) => {
      clearInterval(interval);
      intervalRef.current = [];
    });
  };

  const startGiftCardPolling = async (guid: string) => {
    if (!guid && intervalRef.current.length > 1) return;

    const interval = setInterval(async () => {
      try {
        const response = await fetchGiftCardStatus(guid);
        setGiftCardStatus(response.data);
      } catch (error) {
        stopGiftCardPolling();
        setPayload({ giftcardguid: undefined, giftcard: false });
        setGiftCardStatus(null);
        setGiftCardStatus(null);
      }
    }, POLLING_INTERVAL);

    if (intervalRef.current.length === 1) {
      clearInterval(interval);
    } else {
      intervalRef.current?.push(interval);
    }
  };

  useEffect(setOffset, [scrollY]);

  useEffect(() => {
    setOffset();

    return () => {
      stopGiftCardPolling();
      setPayload({ giftcardguid: undefined, giftcard: false });
    };
  }, []);

  useEffect(() => {
    if (show) {
      setSize({ width: `${wrapperWidth.current}px`, height: `${wrapperHeight.current}px` });
      setTimeout(() => {
        contentElmRef.current?.style.setProperty("display", "block");
        contentElmRef.current?.style.setProperty("opacity", "1");
      }, 300);
    } else {
      contentElmRef.current?.style.setProperty("opacity", "0");
      setSize({ width: "50px", height: "50px" });

      setTimeout(() => {
        contentElmRef.current?.style.setProperty("display", "none");
      }, 300);
    }
  }, [show]);

  const titleText = useMemo(() => {
    return giftCardContent?.headline.replace("{{amount}}", giftCardContent?.amount.toString() ?? "") ?? "";
  }, [giftCardContent?.headline, giftCardContent?.amount]);

  const subtitleText = useMemo(() => {
    const remaining = giftCardStatus?.giftCardsRemaining ?? giftCardContent?.giftCardsRemaining ?? 0;

    if (remaining === 0) return null;

    return giftCardContent?.giftCardsRemainingText.replace("{{GiftCardsRemaining}}", `<b>${remaining.toString()}</b>` ?? "") ?? "";
  }, [giftCardContent?.giftCardsRemainingText, giftCardContent?.giftCardsRemaining, giftCardStatus?.giftCardsRemaining]);

  const usersOnlineText = useMemo(() => {
    const activeUsers = giftCardStatus?.numberOfCurrentUsers ?? giftCardContent?.numberOfCurrentUsers ?? 0;

    if (activeUsers === 0) return null;

    return giftCardContent?.numberOfCurrentUsersText.replace("{{Users}}", `<b>${activeUsers.toString()}</b>` ?? "") ?? "";
  }, [giftCardContent?.numberOfCurrentUsersText, giftCardContent?.numberOfCurrentUsers, giftCardStatus?.numberOfCurrentUsers]);

  const showPopup = useMemo(() => {
    const remainingGiftCards = giftCardStatus?.giftCardsRemaining ?? giftCardContent?.giftCardsRemaining ?? 0;
    return !!giftCardContent?.giftCardId && (giftCardContent?.giftCardsUnlimited || remainingGiftCards > 0);
  }, [giftCardContent, giftCardStatus]);

  useEffect(() => {
    adjustWrapperSize();
  }, [windowSize.width, showPopup]);

  
  return showPopup ? (
    <Wrapper offset={offset}>
      <Inner>
        <PopupWrapper
          show={show}
          size={size}
          ref={wrapperElmRef}
          onClick={() => {
            if (!show) {
              setShow(true);
            }
          }}
        >
          <TbConfetti size={45} color="var(--color-red)" />

          <PopupContent show={show} ref={contentElmRef}>
            <PopupTitle>{titleText}</PopupTitle>
            {subtitleText && (
              <PopupSubTitle>
                <span dangerouslySetInnerHTML={{ __html: subtitleText }}></span>
                <MdOutlineInfo size={18} style={{ marginLeft: 5, cursor: "pointer" }} onClick={() => setShowModal(true)} />
              </PopupSubTitle>
            )}
            {usersOnlineText && (
              <PopupAvailable>
                <PulseCircle />
                <span dangerouslySetInnerHTML={{ __html: usersOnlineText }}></span>
              </PopupAvailable>
            )}
            <PopupCollapsButton
              onClick={() => {
                setShow(false);
              }}
            >
              <BiCollapseAlt size={16} style={{ minWidth: 32 }} />
            </PopupCollapsButton>
          </PopupContent>
        </PopupWrapper>
      </Inner>
      <Modal modalDescriptionHtml={giftCardContent?.giftCardTerms ?? ""} open={showModal} onClose={() => setShowModal(false)} />
    </Wrapper>
  ) : null;
}
