import React, { useEffect, useMemo } from "react";
import { useStore } from "../../store/store";
import StepRoutes from "../../constants/Routes";
import OccupationStep from "./Steps/OccupationStep";
import ProductsStep from "./Steps/ProductsStep";
import WorkingDenmarkStep from "./Steps/WorkingDenmarkStep";
import SelfEmployedStep from "./Steps/SelfEmployedStep";
import ReceivingUnemploymentBenefitsStep from "./Steps/ReceivingUnemploymentBenefitsStep";
import FitWithYouStep from "./Steps/FitWithYouStep";
import NeedToContactYouStep from "./Steps/NeedToContactYouStep";
import InformationAboutYouStep from "./Steps/InformationAboutYouStep";
import SummaryStep from "./Steps/SummaryStep";
import CompletedStep from "./Steps/CompletedStep";
import CompletedContactYouStep from "./Steps/CompletedContactYouStep";
import PaymentStep from "./Steps/PaymentStep";
import { ConfigModel } from "../../types/ConfigModels";
import { fetchTranslations, fetchUnemploymentInsuranceOptions, fetchUnionOptions } from "../../utils/api";
import MembershipStep from "./Steps/MembershipStep";
import ConsentStep from "./Steps/ConsentStep";
import { InnerWrapper, Wrapper } from "./CompanionEnrollment.styles";
import CurrentStatusStep from "./Steps/CurrentStatusStep";
import { PaymentTypeEnum } from "../../types/PaymentTypesEnum";

import translationsData from "../../utils/translations__companionflow.json";
import { TrackingFlowTypeEnum } from "../../types/TrackingFlowTypeEnum";

export default function CompanionEnrollment() {
  const routeStack = useStore.useRouteStack();
  const setPayload = useStore.useSetPayload();
  const setRouteStack = useStore.useSetRouteStack();
  const setOfferEnabled = useStore.useSetOfferEnabled();
  const setFlowType = useStore.useSetFlowType();
  const setTranslations = useStore.useSetTranslation();
  const setUnionOptions = useStore.useSetUnionOptions();
  const setUnemploymentInsuranceOptions = useStore.useSetUnimploymentInsuranceOptions();

  useEffect(() => {
    // Since this is the companion flow we change initial route to CurrentStatusStep
    setRouteStack(StepRoutes.CurrentStatusStep);

    const fetchData = async () => {
      try {
        let translations: any;
        const settingsUrl = form?.dataset.jsonSettingsUrl;

        translations = await fetchTranslations(settingsUrl);
        setTranslations(translationsData);

        const unemploymentInsuranceOptions = await fetchUnemploymentInsuranceOptions();
        const unionOptions = await fetchUnionOptions();

        setUnionOptions(unionOptions.data);
        setUnemploymentInsuranceOptions(unemploymentInsuranceOptions.data);
      } catch (err) {}
    };

    const form = document.querySelector(`[data-component='companion-enrollment']`) as HTMLElement;

    fetchData();

    // Set invitation id
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ownerId = urlParams.get("id") ?? "";
    setPayload({ ownerId });
    setPayload({ paymentType: PaymentTypeEnum.BSPAYMENT });

    // Set owner firstname, lastname and email from dataset
    if (form?.dataset.ownerFirstname && form?.dataset.ownerFirstname !== "null") {
      setPayload({
        firstName: form?.dataset.partnerFirstname,
        lastName: form?.dataset.partnerLastname,
        mailAddress: form?.dataset.partnerEmail,
      });
    }

    // Set settingsNodeKey
    if (form?.dataset.config && form?.dataset.config !== "null") {
      const config = JSON.parse(form?.dataset.config) as ConfigModel;

      setOfferEnabled(false);
      setFlowType(TrackingFlowTypeEnum.PARTNER);
      setPayload({ settingsNodeKey: config.Key });
    }
  }, []);

  const currentStep = useMemo(() => {
    return routeStack[routeStack.length - 1];
  }, [routeStack]);

  const step = useMemo(() => {
    switch (currentStep) {
      case StepRoutes.CurrentStatusStep:
        return <CurrentStatusStep />;
      case StepRoutes.OccupationStep:
        return <OccupationStep />;
      case StepRoutes.ProductStep:
        return <ProductsStep />;
      case StepRoutes.MembershipStep:
        return <MembershipStep />;
      case StepRoutes.WorkingDenmarkStep:
        return <WorkingDenmarkStep />;
      case StepRoutes.FitWithYouStep:
        return <FitWithYouStep />;
      case StepRoutes.NeedToContactYouStep:
        return <NeedToContactYouStep />;
      case StepRoutes.ReceivingUnemploymentBenefitsStep:
        return <ReceivingUnemploymentBenefitsStep />;
      case StepRoutes.SelfEmployedStep:
        return <SelfEmployedStep />;
      case StepRoutes.InformationAboutYouStep:
        return <InformationAboutYouStep />;
      case StepRoutes.SummaryStep:
        return <SummaryStep />;
      case StepRoutes.PaymentStep:
        return <PaymentStep />;
      case StepRoutes.CompletedStep:
        return <CompletedStep />;
      case StepRoutes.CompletedContactYouStep:
        return <CompletedContactYouStep />;
      case StepRoutes.ConsentStep:
        return <ConsentStep />;
    }
  }, [currentStep]);

  return (
    <Wrapper
      id="ASEEnrollmentForm"
      style={{
        backgroundColor: currentStep === StepRoutes.CompletedStep || currentStep === StepRoutes.CompletedContactYouStep ? "var(--color-petrol)" : "transparent",
      }}
    >
      <InnerWrapper>{step}</InnerWrapper>
    </Wrapper>
  );
}
